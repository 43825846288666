.cards {
  margin: 1rem 0.5rem;
}

.cardMain {
  margin-top:15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .cardMain {
    margin-top: 0rem;
    margin-left: 6rem;
  }
}

.submitBtn
{
  cursor: not-allowed;
}