.web3navbar
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (max-width:567px) {
    .balance
    {
    font-size: 1rem;
    } 
    .navbarBrand
    {
     font-weight: 600 !important;
     font-size: 0.8rem !important;
     margin-left: -1.5rem !important;
    }   
}

 